<template>
	<div class="stats">
		<h2 class="stats-title">Overview</h2>
		<div class="stats-container">
			<stats-card
				:quantity="incompleteCalls.urgent"
				title="Total Urgent"
				urgent
				sub-title="Incomplete Calls"
				:is-active="isActive('urgent')"
				@click="toggleActive('urgent')"
			/>
			<stats-card
				non-urgent
				:quantity="incompleteCalls.nonUrgent"
				title="Total Non Urgent"
				sub-title="Incomplete Calls"
				:is-active="isActive('nonUrgent')"
				@click="toggleActive('nonUrgent')"
			/>
			<stats-card
				v-if="hasPagingEnabled"
				:quantity="incompletePages.total"
				title="Total Pages"
				paging
				sub-title="Incomplete Pages"
				:is-active="isActive('paging')"
				@click="toggleActive('paging')"
			/>
		</div>
		<h2 class="stats-title">Call Types</h2>
		<div class="stats-container">
			<stats-card
				v-for="type in types"
				:id="type.id"
				:key="type.id"
				:quantity="type.total"
				:title="type.name"
				sub-title="Incomplete Calls"
				:is-active="isActive(type.id)"
				@click="toggleActive(type.id)"
			/>
		</div>
	</div>
</template>

<script>
import StatsCard from '@/components/StatsCard'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		StatsCard,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		...mapGetters({
			hasPagingEnabled: 'partners/hasPagingEnabled',
			partner: 'partner',
			types: 'callTypes',
		}),

		...mapState({
			incompleteCalls: state => state.calls.incomplete,
			incompletePages: state => state.pages.incomplete,
			user: state => state.auth.user,
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Update Intercom instance.
		 *
		 */
		updateIntercom() {
			const roles = []
			for (const key in this.user.roles) {
				if (
					this.user.roles[key].is_partner_role === true &&
					(this.user.roles[key].name === 'office_manager' ||
						this.user.roles[key].name === 'partner-admin')
				) {
					!roles.includes('manager') && roles.push('manager')
				}
			}
			for (const key in this.user.providers) {
				if (
					this.user.providers[key].active === true &&
					this.user.providers[key].user_id === this.user.id
				) {
					!roles.includes('provider') && roles.push('provider')
				}
			}
			if (window.Intercom) {
				window.Intercom('shutdown')
				window.Intercom('boot', {
					api_base:
						process.env.VUE_APP_INTERCOM_API ||
						'https://api-iam.intercom.io',
					app_id: process.env.VUE_APP_INTERCOM_ID || 'z1oijek0',
					user_id: this.user.id,
					name: this.user.first_name + ' ' + this.user.last_name,
					email: this.user.email,
					clarus_role: roles.toString(),
					user_hash: this.user.intercom_hash_web,
				})
			}
		},

		isActive(cardId) {
			return this.activeCard === cardId
		},

		toggleActive(cardId) {
			this.activeCard = this.activeCard === cardId ? null : cardId
		},

		/**
		 * Fetch the paging count.
		 *
		 * @return {void}
		 */
		async fetchPagingCount() {
			if (!this.partner || !this.partner.id) {
				return
			}

			try {
				await this.getIncompletePagingCount()
			} catch (e) {
				console.error(e)
			}
		},

		...mapActions('pages', ['getIncompletePagingCount']),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Index',

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Active partner watcher.
		 *
		 * @type {Object}
		 */
		partner: {
			handler: 'fetchPagingCount',
			immediate: true,
		},
		/**
		 * Active user watcher.
		 *
		 * @type {Object}
		 */
		user: {
			handler: 'updateIntercom',
			immediate: true,
		},
	},

	data() {
		return {
			activeCard: null,
		}
	},
}
</script>
